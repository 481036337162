html, body, main {
  width: 100%;
}

body {
  font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
}

a, a:visited {
  color: inherit;
  transition: color .2s;
}
a:hover {
  color: red;
}

hr {
  margin: 64px auto;
  width: 150px;
}

svg path {
  fill: currentColor;
}

.avatar {
  border-radius: 50%;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  display: inline-block;
  font-size: 72px;
  height: 1em;
  width: 1em;
}

.landing > section {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 50vh;
  padding: 10vh 0;
}

.landing > section:first-of-type {
  min-height: 100vh;
}

.landing > section.dark {
  background: #181818;
  color: white;
}

.landing h1 {
  font-size: 3.5em;
  font-weight: 400;
  margin: 0 0 -0.15em;
}

.landing h2 {
  font-size: 2.5em;
  font-weight: 400;
  margin: 0 0 1em;
  text-align: center;
}

@media (max-width: 600px) {
  .landing h2 {
    font-size: 2em;
  }
}

.landing .avatar {
  font-size: 4.5rem;
}

.landing svg {
  font-size: 20px;
  height: 2em;
  width: 2em;
}

.landing img[src^="https://gh-card.dev/repos"] {
  max-width: 90vw;
}

.max-width {
  width: 90%;
  max-width: 950px;
}

.semi-max-width {
  width: 80%;
  max-width: 800px;
}

.row {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-content-center {
  justify-content: center;
}

.text-secondary {
  color: #6d767e;
}

.mt-0 { margin-top: 0; }
.mt-1 { margin-top: 0.25rem; }
.mt-2 { margin-top: 0.5rem; }
.mt-3 { margin-top: 1rem; }
.mt-4 { margin-top: 1.5rem; }
.mt-5 { margin-top: 3rem; }

.mb-0 { margin-bottom: 0; }
.mb-1 { margin-bottom: 0.25rem; }
.mb-2 { margin-bottom: 0.5rem; }
.mb-3 { margin-bottom: 1rem; }
.mb-4 { margin-bottom: 1.5rem; }
.mb-5 { margin-bottom: 3rem; }

.ml-0 { margin-left: 0; }
.ml-1 { margin-left: 0.25rem; }
.ml-2 { margin-left: 0.5rem; }
.ml-3 { margin-left: 1rem; }
.ml-4 { margin-left: 1.5rem; }
.ml-5 { margin-left: 3rem; }

.mr-0 { margin-right: 0; }
.mr-1 { margin-right: 0.25rem; }
.mr-2 { margin-right: 0.5rem; }
.mr-3 { margin-right: 1rem; }
.mr-4 { margin-right: 1.5rem; }
.mr-5 { margin-right: 3rem; }

.mx-1 { margin-left: 0.25rem; margin-right: 0.25rem; }
.mx-2 { margin-left: 0.5rem; margin-right: 0.5rem; }
.mx-3 { margin-left: 1rem; margin-right: 1rem; }
.mx-4 { margin-left: 1.5rem; margin-right: 1.5rem; }
.mx-5 { margin-left: 3rem; margin-right: 3rem; }

.my-0 { margin-top: 0; margin-bottom: 0; }
.my-1 { margin-top: 0.25rem; margin-bottom: 0.25rem; }
.my-2 { margin-top: 0.5rem; margin-bottom: 0.5rem; }
.my-3 { margin-top: 1rem; margin-bottom: 1rem; }
.my-4 { margin-top: 1.5rem; margin-bottom: 1.5rem; }
.my-5 { margin-top: 3rem; margin-bottom: 3rem; }

.spacing-mx-2 > * { margin-left: 0.5rem; margin-right: 0.5rem; }
.spacing-mx-3 > * { margin-left: 1rem; margin-right: 1rem; }

.spacing-my-2 > * { margin-top: 0.5rem; margin-bottom: 0.5rem; }
.spacing-my-3 > * { margin-top: 1rem; margin-bottom: 1rem; }

.pt-0 { padding-top: 0; }
.pt-1 { padding-top: 0.25rem; }
.pt-2 { padding-top: 0.5rem; }
.pt-3 { padding-top: 1rem; }
.pt-4 { padding-top: 1.5rem; }
.pt-5 { padding-top: 3rem; }

.pb-0 { padding-bottom: 0; }
.pb-1 { padding-bottom: 0.25rem; }
.pb-2 { padding-bottom: 0.5rem; }
.pb-3 { padding-bottom: 1rem; }
.pb-4 { padding-bottom: 1.5rem; }
.pb-5 { padding-bottom: 3rem; }

.pl-0 { padding-left: 0; }
.pl-1 { padding-left: 0.25rem; }
.pl-2 { padding-left: 0.5rem; }
.pl-3 { padding-left: 1rem; }
.pl-4 { padding-left: 1.5rem; }
.pl-5 { padding-left: 3rem; }

.pr-0 { padding-right: 0; }
.pr-1 { padding-right: 0.25rem; }
.pr-2 { padding-right: 0.5rem; }
.pr-3 { padding-right: 1rem; }
.pr-4 { padding-right: 1.5rem; }
.pr-5 { padding-right: 3rem; }

.px-1 { padding-left: 0.25rem; padding-right: 0.25rem; }
.px-2 { padding-left: 0.5rem; padding-right: 0.5rem; }
.px-3 { padding-left: 1rem; padding-right: 1rem; }
.px-4 { padding-left: 1.5rem; padding-right: 1.5rem; }
.px-5 { padding-left: 3rem; padding-right: 3rem; }

.py-0 { padding-top: 0; padding-bottom: 0; }
.py-1 { padding-top: 0.25rem; padding-bottom: 0.25rem; }
.py-2 { padding-top: 0.5rem; padding-bottom: 0.5rem; }
.py-3 { padding-top: 1rem; padding-bottom: 1rem; }
.py-4 { padding-top: 1.5rem; padding-bottom: 1.5rem; }
.py-5 { padding-top: 3rem; padding-bottom: 3rem; }
